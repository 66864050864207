import type Oas from 'oas';

import { useState, useEffect, useRef } from 'react';

/**
 * Dereferences an API definition and returns the dereferenced OAS instance.
 * Yes, we dereference the OAS on the client. Not ideal!
 *
 * @param apiDefinition The OpenAPI definition to dereference
 */
export default function useDereference(apiDefinition: Oas) {
  const errorRef = useRef<Error | null>(null);
  // @ts-expect-error - `dereferencing` is a valid property on `Oas`
  const [isDereferenced, setIsDereferenced] = useState(apiDefinition?.dereferencing?.complete || false);

  // resets the dereferenced state when the API definition changes
  useEffect(() => {
    if (apiDefinition?.api?._id) {
      setIsDereferenced(false);
    }
  }, [apiDefinition?.api?._id]);

  useEffect(() => {
    let isCanceled = false;

    async function dereference() {
      try {
        await apiDefinition.dereference();
        errorRef.current = null;
      } catch (e) {
        errorRef.current = e;
      }
      if (!isCanceled) setIsDereferenced(true);
    }

    if (!isDereferenced) dereference();

    return () => {
      isCanceled = true;
    };
  }, [isDereferenced, apiDefinition?.api?._id]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isDereferenced, apiDefinition, error: errorRef.current };
}
